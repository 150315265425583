<template>
  <TabContent :loading="initialLoadHasNotCompleted" :errorOccurred="errorOccurred">
    <InfiniteScroll
      class="story-container"
      v-show="initialLoadHasCompleted"
      :getFnc="getCompletedStories"
      :showInitialLoadingIndicator="false"
      @dataChange="handleUserStoriesUpdated($event)"
      @errorOccurred="handleError()"
      errorMessage="'Uh oh! An error occurred while fetching your completed stories. 🤨'"
    >
      <transition-group name="story">
        <router-link
          v-for="story in stories"
          :key="story.id"
          :to="`/stories/completed/${story.id}`"
        >
          <div class="story" :style="getCalculatedFontSizeStyle(story)">
            <div class="cover" :class="getColorSchemeClass(story)">
              <div class="cover-image" :style="getBackgroundImageStyle(story)"></div>
              <SmallLoadingSpinner />
              <img
                v-if="showSticker(story)"
                class="sticker"
                :style="getStickerStyle(story)"
                src="../assets/nba-winner-sticker.png"
                alt="National book award sticker"
              />
              <div class="title" :style="getSeededRandomFontStyle(story)">{{ story.name }}</div>
            </div>
          </div>
        </router-link>
      </transition-group>
    </InfiniteScroll>
    <transition name="no-stories-found">
      <MysticalObject
        v-if="noStoriesFound"
        type="embarassed"
        message="You haven't finished any stories yet."
      >
      </MysticalObject>
    </transition>
  </TabContent>
</template>
<script>
import TabContent from '../components/TabContent.vue';
import InfiniteScroll from '../components/InfiniteScroll.vue';
import SmallLoadingSpinner from '../components/SmallLoadingSpinner.vue';
import MysticalObject from '../components/MysticalObject.vue';
import { getCompletedStories } from '../services/storiesService';

export default {
  name: 'InProgressStories',
  components: {
    TabContent,
    InfiniteScroll,
    SmallLoadingSpinner,
    MysticalObject,
  },
  data() {
    return {
      stories: null,
      errorOccurred: false,
      fonts: [
        'Shrikhand',
        'Fredoka One',
        'Abril Fatface',
        'Potta One',
        'Permanent Marker',
        'Hachi Maru Pop',
        'Cinzel',
        'Courgette',
        'Playfair Display SC',
        'Poiret One',
        'Press Start 2P',
        'Special Elite',
        'New Rocker',
        'Berkshire Swash',
        'Rye',
        'Creepster',
      ],
      fontAlignments: ['text-align: center;', 'text-align: left;', 'text-align: right;'],
      fontVerticalPlacements: ['bottom: 0;', 'top: 0;', 'top: 33%;'],
      getCompletedStories,
    };
  },
  computed: {
    initialLoadHasCompleted() {
      return !!this.stories;
    },
    initialLoadHasNotCompleted() {
      return !this.stories;
    },
    noStoriesFound() {
      return this.stories?.length === 0;
    },
  },
  methods: {
    handleUserStoriesUpdated(newStoriesList) {
      this.stories = newStoriesList;
    },
    handleError() {
      this.errorOccurred = true;
    },
    getCalculatedFontSizeStyle(story) {
      return `font-size: ${40 / story.name.length}rem`;
    },
    // TODO: refactor all of these
    getSeededRandomFontStyle(story) {
      const fontFamilyIndex = parseInt(story.id[0], 16);
      const fontAlignmentIndex = parseInt(story.id[1], 16);
      const fontVerticalPlacementIndex = parseInt(story.id[2], 16);
      const fontFamily = `font-family: ${this.fonts[fontFamilyIndex]}, sans-serif;`;
      const fontAlignment = this.fontAlignments[fontAlignmentIndex % 3];
      const fontVerticalPlacement = this.fontVerticalPlacements[fontVerticalPlacementIndex % 3];
      return `${fontFamily} ${fontAlignment} ${fontVerticalPlacement}`;
    },
    getColorSchemeClass(story) {
      const colorSchemeIndex = parseInt(story.id[3], 16);
      return `color-scheme-${colorSchemeIndex}`;
    },
    getBackgroundImageStyle(story) {
      return `background-image: url("https://picsum.photos/seed/${story.name}/200/300.webp")`;
    },
    getStickerStyle(story) {
      return parseInt(story.id[2], 16) % 3 === 1 ? 'bottom: 0; top: unset;' : '';
    },
    showSticker(story) {
      return parseInt(story.id[3], 16) === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.story-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  grid-gap: 2rem;
  justify-content: center;

  .story {
    background-color: var(--color-white);
    width: 20rem;
    height: 28rem;
    perspective: 1000px;
    transform-style: preserve-3d;
    position: relative;
    font-size: 3rem;
    transition: transform 600ms ease, opacity 600ms ease;

    &:hover {
      transform: translateY(-1rem);
    }

    .cover {
      font-family: 'Shrikhand', sans-serif;
      background-color: var(--color-purple-darken-2);
      height: 28rem;
      transform-origin: left;
      transform: rotateY(0deg);
      position: absolute;
      width: 20rem;
      transition: transform 400ms ease, filter 400ms ease, box-shadow 400ms ease;
      outline: 1px solid transparent;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .spinner {
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 50%;
        opacity: 0.5;
        z-index: -1;
      }

      .cover-image {
        height: 100%;
        width: 100%;
      }

      .title {
        position: absolute;
        margin: 1rem;
        padding: 1rem;
      }

      .sticker {
        position: absolute;
        right: 0;
        margin: 1rem;
      }

      &:hover {
        transform: rotateY(-23deg);
        filter: brightness(1.05);
        box-shadow: var(--dark-shadow);
      }

      &.color-scheme-1 {
        color: white;
        .title {
          background: black;
        }
      }

      &.color-scheme-2 {
        color: white;
        .cover-image {
          filter: brightness(0.3);
        }
      }

      &.color-scheme-3 {
        color: black;
        .title {
          background: white;
          border-radius: 1.5rem;
        }
      }

      &.color-scheme-4 {
        color: white;
        .cover-image {
          filter: hue-rotate(180deg) saturate(2) brightness(0.5);
        }
      }

      &.color-scheme-5 {
        color: white;
        .cover-image {
          filter: sepia(1);
        }
      }

      &.color-scheme-6 {
        color: white;
        .cover-image {
          filter: grayscale(1) contrast(5) brightness(0.5);
        }
      }

      &.color-scheme-7 {
        color: white;
      }

      &.color-scheme-8 {
        color: black;
        .cover-image {
          filter: grayscale(1) contrast(0.5) brightness(2.5);
        }
      }

      &.color-scheme-9 {
        color: black;
        .cover-image {
          filter: invert(1) hue-rotate(270deg);
        }
        .title {
          background: white;
          border-radius: 50%;
          padding: 2rem;
        }
      }

      &.color-scheme-10 {
        color: black;
        position: relative;

        .cover-image {
          filter: hue-rotate(270deg);
        }
        .title::after {
          content: 'Now a major motion picture';
          font-size: 1rem;
          line-height: 1;
          position: absolute;
          background: black;
          color: white;
          padding: 1rem;
          right: 1rem;
          bottom: -1rem;
        }
      }

      &.color-scheme-11 {
        color: black;
        .cover-image {
          filter: grayscale(1) contrast(0.5) brightness(2.5);
        }
        .title {
          transform: rotate(45deg);
        }
      }

      &.color-scheme-12 {
        color: white;
        .cover-image {
          filter: grayscale(1) contrast(0.5) brightness(0.5);
        }
        .title {
          writing-mode: vertical-lr;
          line-height: 0.5;
          top: 0;
          background: #ac2626;
          padding: 1.5rem;
        }
      }

      &.color-scheme-13 {
        color: white;
        .cover-image {
          filter: grayscale(1) contrast(0.5) brightness(2.5);
        }
        .title {
          line-height: 0.5;
          top: 0;
          right: 0;
          background: #5026ac;
          padding-top: 50%;
          border-radius: 50%;
        }
      }

      &.color-scheme-0 {
        color: white;
        .title::after {
          content: ' ';
          height: 3rem;
          width: 3rem;
          background: white;
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          display: block;
        }
      }

      &.color-scheme-15 {
        color: white;
        .cover-image {
          filter: grayscale(1) contrast(0.5) brightness(2.5);
        }
        .title {
          line-height: 0.5;
          top: 0;
          right: 0;
          background: #df6c19;
          padding-top: 50%;
        }
      }

      &.color-scheme-16 {
        color: white;
        .title::after {
          content: ' ';
          height: 3rem;
          width: 3rem;
          background: #df19ae;
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          display: block;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.story-enter-active,
.story-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.story-enter-from,
.story-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
</style>
